<!-- <div class="w-100">
  
    <div class="row w-100">
      <div class="col-9 text-center" style="background-color: #6D6875; color: white;">
        <div style="margin-top: 10%;">
        <h3 class="text-center my-3" style="font-size: 35px;"><b> Kendine en uygun bedeni bul !</b></h3>
        <span class="text-center"><b>Sizin için ne yaptığımız gösterebilmek için küçük bir demo hazırladık. Ürünlere tıklayarak CabinFit deneyimini yaşayabilisin. </b></span>

        <div>
          <img style="width: 300px;" src="../../assets/262-emoji-wow-outline.gif" alt="">
        </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col p-0">
            <div class="card" (click)="sendProduct(3, 3, 'https://cdn-dmod.akinon.net/products/2021/04/14/316160/27b5458f-7b67-4d4a-b52f-78491be32bd9_size370x555_quality90.jpg')" >
              <img class="card-img-top" src="https://cdn-dmod.akinon.net/products/2021/04/14/316160/27b5458f-7b67-4d4a-b52f-78491be32bd9_size370x555_quality90.jpg" alt="Card image cap">
             
            </div>
          </div>
          <div class="col p-0">
            <div class="card" (click)="sendProduct(3, 3, 'https://cdn-dmod.akinon.net/products/2020/09/21/271816/eff48efe-c04a-4868-a415-7c69337664ad_size370x555_quality90.jpg')" >
              <img class="card-img-top" src="https://cdn-dmod.akinon.net/products/2020/09/21/271816/eff48efe-c04a-4868-a415-7c69337664ad_size370x555_quality90.jpg" alt="Card image cap">
              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col p-0">
            <div class="card" (click)="sendProduct(3, 3, 'https://kigili.akinoncdn.com/products/2021/05/12/197093/8a45f254-84a5-4576-b07a-e85a789924d5_size269x402_quality90_cropCenter.jpg')" >
              <img class="card-img-top" src="https://kigili.akinoncdn.com/products/2021/05/12/197093/8a45f254-84a5-4576-b07a-e85a789924d5_size269x402_quality90_cropCenter.jpg" alt="Card image cap">
             
            </div>
          </div>
          <div class="col p-0">
            <div class="card" (click)="sendProduct(3, 3, 'https://kigili.akinoncdn.com/products/2021/05/05/195836/ba2cc2ff-4b37-493d-ab73-0cae789b5b2f_size269x402_quality90_cropCenter.jpg')">
              <img class="card-img-top" src="https://kigili.akinoncdn.com/products/2021/05/05/195836/ba2cc2ff-4b37-493d-ab73-0cae789b5b2f_size269x402_quality90_cropCenter.jpg" alt="Card image cap">
             
            </div>
          </div>
        </div>
        
      
       
      </div>
       

    </div>
   
</div> -->

<div style="width: 70%; margin: auto;" class="shadow mt-4 p-2 rounded">
 <div class="text-center">
  <span class="text-center"><b>Sizin için ne yaptığımız gösterebilmek için küçük bir demo hazırladık. Ürünlere tıklayarak CabinFit deneyimini yaşayabilisin. </b></span>
 </div>

 <div class="row">
<div class="col-4">
  <div class="row clothes tshirt p-3">
    <img src="../../assets/filled outline.svg" alt="">
  </div>
  <div class="row clothes trouser p-3">
    <img src="../../assets/skirt.svg" alt="">
  </div>
</div>
<div class="col-4">
  <div class="row body">
    <img src="../../assets/16-avatar-woman-nodding-outline (1).gif" style="width: 40%; margin: auto;" alt="">
  </div>
  <div class="row content" >
    <span style="width: 70%; margin-left: 15%; text-align: center;">
      CabinFit deneyimini yaşayabilmek için görsellere tıklayabilirsiniz.
    </span>
  </div>
</div>
<div class="col-4">
  <div class="row clothes jacket p-3">
    <img src="../../assets/jacket.svg" alt="">
  </div>
  <div class="row clothes sock p-3">
    <img src="../../assets/socks.svg" alt="">
  </div>
</div>

 
 </div>
 
</div>