import { Component, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CabinFit';
  iframeUrl = '';
  showModal: boolean = false;
  urlSafe: SafeResourceUrl;
  successAlert = false;
  showStoryPage: boolean = false;
  color: any;
  topbarBackGroundColor:string = "rgba(255, 255, 255, 0.39)"
  product = [
    {
    brand: 2,
    prod: "KY21BNE0DZ002",
    url: ""
  },
    {
    brand: 2,
    prod: "KY21BNE0DZ002",
    url: ""
  },
    {
    brand: 2,
    prod: "KY21BNE0DZ002",
    url: ""
  },
    {
    brand: 2,
    prod: "KY21BNE0DZ002",
    url: ""
  },
]

  prodModel = {
    name: "",
    productIcon: "",
    productContent: ""
  }
  constructor(public sanitizer: DomSanitizer){

  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.closeIframe();
    this.getSelectedProductInfo(1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(this.color)
  }

  closeIframe() {
    var eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, function (e) {
      if (e.origin !== 'https://cabinfit-76461.web.app') return;
      if (
        e.data === 'closeCabinFitIframe' ||
        e.message === 'closeCabinFitIframe'
      )
        document.getElementById('cabinIframe').style.display = 'none';
    });
  }
  openIframe(url,gender,prod) {
    // TODO: Url alınacak. https://cabinfit-76461.web.app/?productId=KY21BNE0DZ002&customColor=rgb(107, 112, 92)&brand=2&poductUrl=https://kigili.akinoncdn.com/products/2021/05/18/195501/3de28a17-c7db-464e-a506-7fdd95749a2b_size350x525_quality90_cropCenter.jpg&gender=2&isMetric=true
    this.iframeUrl = `https://cabinfit-76461.web.app/?productId=${prod}&brand=1&customColor=rgb(242,%20132,%20130)&brand=2&poductUrl=${url}&gender=${gender}&isDev=true`;
    this.showModal = true;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeUrl
    );
    document.getElementById('cabinIframe').style.display = 'block';
  }
  scroll(scroll){
    window.scrollBy(0, scroll);
  }
  getSelectedProductInfo(productId){
    
    switch(productId) {
      case 1:
        this.prodModel.name = "Web/Mobil Kıyafet Beden Önerisi";
        this.prodModel.productIcon = "../assets/fashion.svg";
        this.prodModel.productContent = "Markaların web site ve mobil uygulamarına entegre çalışan, üst ve alt giyim kategorilerinde ürün bazlı beden önerisi sunan web uygulamamız."
        break;
      case 2:
        this.prodModel.name = "Web/Mobil Ayakkabı Beden Önerisi";
        this.prodModel.productIcon = "../assets/gym-shoes.svg";
        this.prodModel.productContent = "Markaların web site ve mobil uygulamarına entegre çalışan, kullanıcılara ayakkabı beden önerisi yapan web uygulamamız."
        break;
        case 3:
          this.prodModel.name = "Instagram Beden Önerisi";
          this.prodModel.productIcon = "../assets/like.svg";
          this.prodModel.productContent = "Çok Yakında."
          break;
      default:
        // code block
    }
  }

  colorChanged(event){
    console.log(event,"color")
  }
  isScroll(){
    console.log("-----")
  }

  
}
