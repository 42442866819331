import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dummy-site-section',
  templateUrl: './dummy-site-section.component.html',
  styleUrls: ['./dummy-site-section.component.scss']
})
export class DummySiteSectionComponent implements OnInit {

  @Output() product: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  sendProduct(brand, prod, url){
    let product = {
      brand: brand,
      prod: prod,
      url: url
    }
    this.product.emit(product);
  }
}
