
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { TopbarComponent } from './topbar/topbar.component';
import { DummySiteSectionComponent } from './dummy-site-section/dummy-site-section.component';
import { FaqSectionComponent } from './faq-section/faq-section.component';
import { FooterComponent } from './footer/footer.component';
import { StoryComponent } from './story/story.component';

//  const materialModules = [
//    CdkTreeModule,
//    MatAutocompleteModule,
//    MatButtonModule,
//    MatCardModule,
//    MatCheckboxModule,
//    MatChipsModule,
//    MatDividerModule,
//    MatExpansionModule,
//    MatIconModule,
//    MatInputModule,
//    MatListModule,
//    MatMenuModule,
//    MatProgressSpinnerModule,
//    MatPaginatorModule,
//    MatRippleModule,
//    MatSelectModule,
//    MatSidenavModule,
//    MatSnackBarModule,
//    MatSortModule,
//    MatTableModule,
//    MatTabsModule,
//    MatToolbarModule,
//    MatFormFieldModule,
//    MatButtonToggleModule,
//    MatTreeModule,
//    OverlayModule,
//    PortalModule,
//    MatBadgeModule,
//    MatGridListModule,
//    MatRadioModule,
//    MatDatepickerModule,
//    MatTooltipModule,
//    MatProgressSpinnerModule
//  ];

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    DummySiteSectionComponent,
    FaqSectionComponent,
    FooterComponent,
    StoryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
