<div>


<div class="p-4" style="background-color: #E36414; color: white;">
    <img src="../../assets/112-book-morph-outline.gif" style="width: 10%; margin-left: 45%;" alt="">
    <h3 class="mt-4" style="text-align: center; font-weight: 500;">
        Hayallerini gerçekleştiren bir ekibin hikayesi
    </h3>
    <hr style="background-color: white; width: 40%;">

<div style="text-align: center;">
    <span >
        2017 de çıktığımız bu yolun hikayesinde birçok heyecan, başarısızlık, azim ve kilometre taşı var. Sizlere kısaca kendimizden bahsetmek istedik.
    </span>
</div>
   
</div>

<div class="p-5" style="background-color: #FB8B24; color: white;">
   
    <h3 class="mt-4" style="text-align: center; font-weight: 500;">
       İTÜ Çekirdek
    </h3>
    <hr style="background-color: white;  width: 40%;">
<div style="text-align: center;">
    <span >
        2017 yılında fikrimizi ete kemiğe büründürmek için İTÜ bünyesindeki kuluçka merkezi olan İTÜ Çekirdek yarışmasına başvurduk ve kabul edildik. Burada bir proje nasıl şirketleşir, yönetilir, finansman sağlar
        ve bir projenin gerçekleşmesi için kaç kez batması gerekir sorularının yanıtlarını öğrendik. Başarısız olmadan, hatalar yapma tecrübesi edinmeden olgunlaşılmayacağını duyduk. Ve çok geçmeden bu tecrübelerin ilkini 
        yarışmanın final aşaması olan BİGBANG etkinliğinde yaşadık...
    </span>
</div>
   
</div>


<div class="p-4" style="background-color: #9A031E; color: white; text-align: center;">
   <div class="imageBox rounded">
    <!-- <img src="../../assets/075B05F9-0186-45B9-83A1-679FF8C901AA (1).JPG" alt="" class="mx-auto" style="width: auto;  border-radius: 8px; height: 20vh;"> -->

   
   </div>
    <h3 class="mt-4" style="text-align: center; font-weight: 500;">
      İlk Heyecanlar
    </h3>
    <hr style="background-color: white;  width: 40%;">
<div style="text-align: center;">
    <span >
       İTÜ Çekirdek bünyesinde o zamanki projemizin ilk AR-GE çalışmalarını harçlıklarımızı biriktirerek 3D printerdan bastırdığımız ve esnafa rica ederek aldığımız malzemeler ile gerçekleştirdik. 
        Aşağıdaki gerçeklediğimiz ilk prototipin çalıştığı ilk ana dair kısa bir video olsa da bu kısa ana ne kadar büyük bir heyecan, mutluluk ve umut sığdığının kelimeler ile tarifi mümkün değil.
    </span>
</div>

<div class="videoBox mt-5">
    <video width="20%" style="border-radius: 20px;" controls>
        <source src="../../assets/IMG_7320.MOV" type="video/mp4">
        <source src="../../assets/IMG_7320.MOV" type="video/ogg">
        Your browser does not support HTML video.
      </video>
</div>
   
</div>

<div class="p-4" style="background-color: #0F4C5C; color: white;">
   
    <h3 class="mt-4" style="text-align: center; font-weight: 500;">
     Şirketleşme, Başarısızlıklar ve Dersler
    </h3>
    <hr style="background-color: white;  width: 40%;">
<div style="text-align: center;">
    <span >
       Yaptığımız prototipin yarışma finaline bir hafta kala bozulması ile sahneye çıkma şansımızı kaybetmiştik. Ardından projeyenin sürdürülebilir olmadığını fark edip pivot ettik. Yeni projemiz ile Cabin Bilişim Teknolojileri A.Ş. yi kurduk.
       Yeni projemiz ile yatırım aldık, ofisimizi açtık ve bir yılın sonunda yeni başarısızlık tecrübeleri edindik, çünkü bu işler böyledir. 4 yılı aşkın zamandır üzerine çalıştığımız sorunda olabilecek tüm yanlışları
       yaptık, derslerimizi aldık ancak hiçbir zaman vaz geçmedik. Tüm tecrübelerimiz ve çalışmalarımız sonucu 2020 yılının sonlarında nihai ürünümüzü sektöre kazandırdık. 
    </span>
</div>
   
</div>

<div class="p-4" style="background-color: #5F0F40; color: white;">
   
    <h3 class="mt-4" style="text-align: center; font-weight: 500;">
    CabinFit Beden Önerisi
    </h3>
    <hr style="background-color: white;  width: 40%;">
<div style="text-align: center;">
    <span >
        CabinFit tüm hatalar, tecrübeler ve geri dönüşler ile harmanlanmış, sektörün ihtiyaçlarına yönelik sektörle birlikte geliştirilmiş ve kendi benzersiz algoritmasını kullanan beden önerisi çözümü ürünüdür. 
        Bu ürünümüz ile markaların satış kanallarına son derece pratik bir şekilde entegre olarak kullanıcılara her ürün için kendilerine en uygun bedeni öneriyoruz. Bu sayede beden uyumsuzluğu kaynaklı iadeleri minimuma indirirken
        güvensizlik yaşayan kullanıcıları da satışa yönlendiriyoruz.
    </span>
</div>
   
</div>

<div class="p-4" style="background-color: #5F0F40; color: white;">
   
    <h4 class="mt-4" style="text-align: center; font-weight: 500;">
  Kısaca, şu anda hayallerini gerçekleştirmekte olan bir ekibin projesini inceliyorsunuz.
    </h4>

   
</div>



</div>