import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.scss']
})
export class FaqSectionComponent implements OnInit {
  public isCollapsed = false;
  selectedAnswer: string = "";
  panelOpenState = false;
  content = [
    {
      id: 0,
      question: "CabinFit Nedir ?",
      answer: "2017 yılında tohumları atılmış 2019 yılında şirketleşmiş bir startup hikayesidir. Markalara, kullanıcılarına doğru beden önermesinde yardımcı olan, bu sayede iadeyi azaltan bir yazılım servisidir."
    },
    {
      id: 1,
      question: "Nasıl Çalışıyor ?",
      answer: "2017 yılında tohumları atılmış 2019 yılında şirketleşmiş bir startup hikayesidir. Markalara, kullanıcılarına doğru beden önermesinde yardımcı olan, bu sayede iadeyi azaltan bir yazılım servisidir."
    },
    {
      id: 2,
      question: "Ücretli mi ?",
      answer: "2017 yılında tohumları atılmış 2019 yılında şirketleşmiş bir startup hikayesidir. Markalara, kullanıcılarına doğru beden önermesinde yardımcı olan, bu sayede iadeyi azaltan bir yazılım servisidir."
    },
    {
      id: 3,
      question: "CabinFit Nedir ?",
      answer: "2017 yılında tohumları atılmış 2019 yılında şirketleşmiş bir startup hikayesidir. Markalara, kullanıcılarına doğru beden önermesinde yardımcı olan, bu sayede iadeyi azaltan bir yazılım servisidir."
    },
  ]

  constructor() { }

  ngOnInit(): void {
    this.selectedAnswer = this.content[0].answer
  }

  getAnswer(id){
    for (let index = 0; index < this.content.length; index++) {
    if(this.content[index].id == id){
      this.selectedAnswer = this.content[index].answer
    } 
      
    }
  }
}
