<img
  class="bgImage"
  src="../assets/Web 1280 – 1.svg"
  alt="CabinFit BG İmage"
  style="width: 100%; position: absolute; opacity: 0.7"
/>

<div
  class="topbar row shadow"
  id="topbar"
  [ngStyle]="{ backgroundColor: topbarBackGroundColor }"
  style="position: sticky; top: 0; left: 0; z-index: 999"
>
  <div class="col-12 p-1">
    <a href="#">
      <img class="logo" src="../assets/cabinfitNewLogo.png" alt="cabinFit Logo" />
    </a>
  </div>
</div>
<div class="container" scroll="isScroll()">
  <div class="row my-5">
    <div class="col-sm-7 landing3">
      <img
        src="../assets/landingImage.svg"
        class="mx-auto"
        style="width: 100%"
        alt="shoppingImageIllustration"
      />
    </div>
    <div class="col-sm-5 my-auto landing1">
      <div>
        <h1 class="mx-auto" style="font-weight: 500">Onine Beden Asistanı</h1>
      </div>
      <div>
        <h2 style="font-size: 18px">
          CabinFit, markaların web site ve mobil uygulamalarına entegre olarak
          kullanıcılara kendilerine en uygun bedeni bulmalarında yardımcı olur.
          İade oranını minimuma indirirken, sağladığı güven ile satışa katkı
          sağlar.
        </h2>
      </div>
      <div class="actions">
        <button
          type="button"
          class="btn btn-danger rounded my-auto tryBtn1"
          style="border-radius: 15px"
        >
        <a href="#section1" style="color: white;">
          Hemen Dene
        </a>
        </button>
        <button
          type="button"
          class="btn btn-danger rounded my-auto tryBtn2"
          style="border-radius: 15px"
        >
        <a href="#section1">
          Hemen Dene
        </a>
        </button>
        <button
          type="button"
          class="btn btn-dark rounded my-auto tryBtn1 mx-2"
          style="border-radius: 15px"
        >
        <a href="#section2" style="color: white;">
          İletişime Geç
        </a>
        </button>
        <button
          type="button"
          class="btn btn-dark rounded my-auto tryBtn2 mx-2"
          style="border-radius: 15px"
        >
        <a href="#section2">
          İletişime Geç
        </a>
        </button>
      </div>
    </div>
    <div class="col-sm-7 landing2">
      <img
        src="../assets/landingImage.svg"
        class="mx-auto"
        style="width: 100%"
        alt="shoppingImageIllustration"
      />
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-8 col-12">
      <h4 style="font-weight: 500">Neden CabinFit ?</h4>
      <div>
        Çünkü bu bizim hayalimiz. 2017 yılında İTÜ bünyesinde başladığımız bu
        yolda Türkiye'nin ilk beden öneri ürününü pazara sunduk. Sınır
        koymadığımız bu hayalde birçok marka başarı hikayelerimiz oldu.
        <br />
        Sizinle paylaşmak isteriz.
      </div>
      <div class="mt-2">
        <span style="cursor: pointer; font-weight: 500">
          Başarı Hikayelerimiz
          <i class="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>
  </div>

  <section class="mt-5">
    <div>
      <div class="row">
        <div class="col-md-3 col-6">
          <div class="icon-box text-center mb-5 mb-md-0">
            <div>
              <img
                class="static"
                style="width: 150px"
                src="../../assets/950-attract-customers-outline.png"
                alt=""
              />
              <img
                style="width: 150px"
                src="../../assets/950-attract-customers-outline.gif"
                alt=""
              />
            </div>
            <h2 class="h5 my-3">Kullanıcı Güveni</h2>
            <p class="px-lg-4">
              Kararsız kullanıcılar online alışverişten kaçarlar. CabinFit beden
              önerisi ile güven sağlayın.
            </p>
          </div>
        </div>
        <div class="col-md-3 col-6 mb-5 mb-md-0">
          <div class="icon-box text-center">
            <div>
              <img
                class="static"
                style="width: 150px"
                src="../../assets/500-fingerprint-security-outline.png"
                alt=""
              />
              <img
                style="width: 150px"
                src="../../assets/500-fingerprint-security-outline.gif"
                alt=""
              />
            </div>
            <h2 class="h5 my-3">Onları Tanıyın</h2>
            <p class="px-lg3">
              CabinFit markalara daha önce sahip olmadığı veriler sunar.
              Kullanıcılarınız vücut ölçüleri ve tercihleri hakkında
              özelleştirilmiş metrikler sunar.
            </p>
          </div>
        </div>
        <div class="col-md-3 col-6 mb-5 mb-md-0">
          <div class="icon-box text-center">
            <div>
              <img
                class="static"
                style="width: 150px"
                src="../../assets/107-box-package-open-outline.png"
                alt=""
              />
              <img
                style="width: 150px"
                src="../../assets/107-box-package-open-outline.gif"
                alt=""
              />
            </div>
            <h2 class="h5 my-3">Satışlarınızı Artırın</h2>
            <p class="px-lg-4">
              Beden öneri sistemi kararsız kullanıcıları inovatif teknolojisi
              ile satışa yönlendirir.
            </p>
          </div>
        </div>
        <div class="col-md-3 col-6 mb-5 mb-md-0">
          <div class="icon-box text-center">
            <div>
              <img
                class="static"
                style="width: 150px"
                src="../../assets/213-arrow-2-rounded.png"
                alt=""
              />
              <img
                style="width: 150px"
                src="../../assets/213-arrow-2-rounded.gif"
                alt=""
              />
            </div>
            <h2 class="h5 my-3">Minimum İade</h2>
            <p class="px-lg-4">
              Kullanıcılarınıza doğru beden önerisi sağlayarak, beden
              uyumsuzluğundan kaynaklanan iadeleri minimuma indirin.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<section>
  <div
    class="row"
    style="
      background-color: rgba(158, 83, 158, 0.041);
      padding-left: 20%;
      padding-right: 20%;
    "
  >
    <div class="col mt-4">
      <h4 class="text-center"><b> Markalar için </b></h4>
      <div class="row mt-5">
        <div class="col-4">
          <img
            src="../assets/jigsaw.svg"
            style="width: 50%; margin-left: 25%; color: purple"
            alt=""
          />
        </div>
        <div class="col-8">
          <b style="font-weight: 500"> Yenilikçi Marka </b>

          <div class="mt-1">
            <span>
              Markanızın en büyük ve en az maliyetli şubesi online mağazadır.
              Yenilikçi <b>CabinFit</b> beden önerisi ile kullanıcılarınıza
              verdiğiniz önemi gösterin.
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-4">
          <img
            src="../assets/boutique.svg"
            style="width: 50%; margin-left: 25%"
            alt=""
          />
        </div>
        <div class="col-8">
          <b style="font-weight: 500"> Gerçek Mağaza Deneyimi </b>

          <div class="mt-1">
            <span>
              Kullanıcılar ürünleri fiziksel olarak deneyimleyip kendisine en
              uygun ürünü almak ister. <b>CabinFit</b> fiziksel bir
              mağazanızdaki satış asistanı gibi online müşterilerinize
              kendilerine en uygun bedeni bulmalarında yardımcı olur.
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-4">
          <img
            src="../assets/data.svg"
            style="width: 50%; margin-left: 25%"
            alt=""
          />
        </div>
        <div class="col-8">
          <b style="font-weight: 500"> Verileri Öngörülere Dönüştürün </b>

          <div class="mt-1">
            <span>
              <b>CabinFit</b> size daha önce sahip olmadığınız veriler sunar.
              Ürün kalıplarınızın hedef müşteri vücutlarına uygunluğuna, ve
              iyileştirmek için neler yapılabileceğine dair bilgieler verir.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col mt-4">
      <h4 class="text-center"><b> Kullanıcılar için </b></h4>
      <div class="row mt-5">
        <div class="col-4">
          <img
            src="../assets/size-guide.svg"
            style="width: 50%; margin-left: 25%; color: purple"
            alt=""
          />
        </div>
        <div class="col-8">
          <b style="font-weight: 500"> Vücüdunuza En uygun Beden </b>

          <div class="mt-1">
            <span>
              Online mağazadan ürün alırken, 'ya olmazsa', 'beklediğimden dar ya
              da uzun gelirse', 'iade etmek ile kim uğraşacak' sorularından sizi
              kurtarıyoruz. Kendinize en uygun bedeni öğrenin ve gönül rahatlığı
              ile satın alın.
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-4">
          <img
            src="../assets/adventurer.svg"
            style="width: 50%; margin-left: 25%"
            alt=""
          />
        </div>
        <div class="col-8">
          <b style="font-weight: 500"> Yeni Markalar Keşfetmekten Korkma </b>

          <div class="mt-1">
            <span>
              <b>CabinFit</b> size her markanın her ürününe özel beden
              önerisinde bulunur. Daha önce satın almadığınız bir markadan
              beğendiğiniz bir ürün satın alırken beden önerimize
              güvenebilirsiniz.
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-4">
          <img
            src="../assets/secure-data.svg"
            style="width: 50%; margin-left: 25%"
            alt=""
          />
        </div>
        <div class="col-8">
          <b style="font-weight: 500"> Verilerin Senin Elinde </b>

          <div class="mt-1">
            <span>
              Kullanıcıların bilgilerinin özel olduğunu biliyoruz. Bu nedenle
              kullanıcı hakkında hiçbir özel veri almıyor, herhangi bir hesapla
              bağlantı kurdurmuyoruz. <b>CabinFit</b> verileriniz kendi
              cihazınızda saklanır. Ve beden önerisi almak için kullanılır.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <section>
    <div class="row mt-2">
      <div class="col-md-4 col-sm-12">
        <hr class="w-100" />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="https://corporate.kigili.com/storage/settings/January2019/LrC16GHDGNL9ndV7Q5id.png"
          style="width: 60%; margin-left: 20%; cursor: pointer"
          alt="Kiğılı Logo"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          class="my-auto"
          src="https://seeklogo.com/images/A/abdullah-kigili-logo-F9B0F2F033-seeklogo.com.png"
          style="width: 80%; margin-left: 10%; cursor: pointer"
          alt="Abdullah Kiğılı Logo"
        />
      </div>
      <div class="col-md-4 col-sm-12">
        <hr class="w-100" />
      </div>
    </div>
  </section>

  <section id="section1">
    <div class="row" >
      <div class="col-md-8 col-sm-12 my-auto">
        <div style="font-weight: 500; text-align: center; font-size: 25px">
          Bizi, <b>CabinFit</b> kullanan markalarımızdan veya
        </div>
        <div style="font-weight: 500; text-align: center; font-size: 20px">
          Sizin için seçtiğimiz ürünlere tıklayarak deneyebilirsiniz.
        </div>
      </div>

      <div class="col-md-2 col-6">
        <div class="mt-4">
          <div class="row">
            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://www.westend61.de/images/0001079238pw/smiling-japanese-woman-with-long-brown-hair-wearing-white-short-sleeved-blouse-standing-in-a-street-MINF09675.jpg',
                  1,
                  'womanShirtWebsite'
                )
              "
            >
              <img
                src="https://www.westend61.de/images/0001079238pw/smiling-japanese-woman-with-long-brown-hair-wearing-white-short-sleeved-blouse-standing-in-a-street-MINF09675.jpg"
                style="width: 100%"
                alt="Kiğılı Logo"
              />
            </div>

            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLXtSJMOBP-_zugAp30bNuOm6ldh4VltpodQ&usqp=CAU',
                  1,
                  'womanTshirtWebsite'
                )
              "
            >
              <img
                class="my-auto"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLXtSJMOBP-_zugAp30bNuOm6ldh4VltpodQ&usqp=CAU"
                style="width: 100%"
                alt="Abdullah Kiğılı Logo"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://us.123rf.com/450wm/ilyabukowski/ilyabukowski2003/ilyabukowski200300094/143206678-woman-in-black-hoodie-mockup-for-logo-or-branding-design.jpg?ver=6',
                  1,
                  'womanSweatshirtWebsite'
                )
              "
            >
              <img
                src="https://us.123rf.com/450wm/ilyabukowski/ilyabukowski2003/ilyabukowski200300094/143206678-woman-in-black-hoodie-mockup-for-logo-or-branding-design.jpg?ver=6"
                style="width: 100%"
                alt="Coming Soon"
              />
            </div>
            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/s80-pai-0895_4.jpg?w=1200&h=1200&dpr=1&fit=clip&crop=default&fm=jpg&q=75&vib=3&con=3&usm=15&cs=srgb&bg=F4F4F3&ixlib=js-2.2.1&s=a36605dea694863bbcd4bbf41259c0f3',
                  1,
                  'womanTshirtWebsite'
                )
              "
            >
              <img
                src="https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/s80-pai-0895_4.jpg?w=1200&h=1200&dpr=1&fit=clip&crop=default&fm=jpg&q=75&vib=3&con=3&usm=15&cs=srgb&bg=F4F4F3&ixlib=js-2.2.1&s=a36605dea694863bbcd4bbf41259c0f3"
                style="width: 100%"
                alt="Coming Soon"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2 col-6">
        <div class="mt-4">
          <div class="row">
            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://kigili.akinoncdn.com/products/2021/06/25/193943/7d50933c-a99e-4629-aa8e-098900f9d62f_size350x525_quality90_cropCenter.jpg',
                  2,
                  'shirtWebsite'
                )
              "
            >
              <img
                src="https://kigili.akinoncdn.com/products/2021/06/25/193943/7d50933c-a99e-4629-aa8e-098900f9d62f_size350x525_quality90_cropCenter.jpg"
                style="width: 100%"
                alt="Kiğılı Logo"
              />
            </div>

            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://kigili.akinoncdn.com/products/2021/09/03/214605/c967ac33-4257-49f8-b2d9-4376c13c6f9d_size350x525_quality90_cropCenter.jpg',
                  2,
                  'tshirtWebsite'
                )
              "
            >
              <img
                class="my-auto"
                src="https://kigili.akinoncdn.com/products/2021/09/03/214605/c967ac33-4257-49f8-b2d9-4376c13c6f9d_size350x525_quality90_cropCenter.jpg"
                style="width: 100%"
                alt="Abdullah Kiğılı Logo"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://kigili.akinoncdn.com/products/2021/09/09/91014/6eef252d-1030-4364-940a-70aa52baa89a_size350x525_quality90_cropCenter.jpg',
                  2,
                  'sweatshirtWebsite'
                )
              "
            >
              <img
                src="https://kigili.akinoncdn.com/products/2021/09/09/91014/6eef252d-1030-4364-940a-70aa52baa89a_size350x525_quality90_cropCenter.jpg"
                style="width: 100%"
                alt="Coming Soon"
              />
            </div>
            <div
              class="col imgBox m-0 p-0"
              (click)="
                openIframe(
                  'https://kigili.akinoncdn.com/products/2021/08/05/97512/524a540f-0667-48dc-ad46-79d8d460cb2f_size350x525_quality90_cropCenter.jpg',
                  2,
                  'trikoWebsite'
                )
              "
            >
              <img
                src="https://kigili.akinoncdn.com/products/2021/08/05/97512/524a540f-0667-48dc-ad46-79d8d460cb2f_size350x525_quality90_cropCenter.jpg"
                style="width: 100%"
                alt="Coming Soon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<section>
  <div
    class="row"
    style="
      background-color: rgba(158, 83, 158, 0.041);
      padding-left: 20%;
      padding-right: 20%;
    "
  >
    <div class="col mt-4">
      <h4 class="text-center">
        <b style="font-style: italic">"Burada her şey size özel"</b>
      </h4>
      <img
        class="w-75 mt-2"
        style="margin-left: 12.5%"
        src="../assets/build.svg"
        alt="cabinFitCustomizeImage"
      />
    </div>
    <div class="col mt-4">
      <div class="row my-3 mt-5">
        <div class="col-12">
          <b style="font-weight: 500"> Sınırsız Beden Önerisi </b>

          <div>
            <span>
              <b>CabinFit</b> ile çalıştığınızda kaç müşterinize iyi hizmet
              vermek istediğinizi düşünmezsiniz. Sınırsız beden önerisi
              vereriyoruz.
            </span>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <b style="font-weight: 500"> Mobil Destekli Tasarım </b>
          <div>
            <span>
              Müşterilerinizin büyük bir kısmının online mağazanızı mobil
              cihazlardan ziyaret ettiğini biliyoruz. <b>CabinFit</b> tüm
              cihazlarda sorunsuz çalışacak şekilde tasarlandı.
            </span>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <b style="font-weight: 500"> Markanıza Göre Özelleştirme </b>
          <div>
            <span>
              <b>CabinFit</b> online mağazanızda 'En uygun bedeni bul' butonuna
              basarak aktifleşir. Markanızın tasarımını <b>CabinFit</b> ile
              devam ettirmek ister misiniz ? Sistemde kullanılacak yazı tipi,
              buton renkleri, sayfaya özel bilgi yazıları, yerleşim düzeni ve
              daha bir çok ayrıntıya birlikte karar verelim. Kullanıcılarınız
              kesintisiz bir deneyim yaşasın.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="section2">
  <div class="row py-5" style="background-color: #264653">
    <div class="mx-auto text-center" style="color: white">
      <div style="font-size: 20px; font-weight: 900">CabinFit ile tanışın</div>
      <div>En geç 24 saat içerisinde dönüş yapıyoruz.</div>
      <div style="font-weight: 900">info@cabin.com.tr</div>
    </div>
  </div>
</section>
<section>
  <div class="row topbar" style="background-color: #264653; color: whitesmoke">
    <div class="col text-center">
      Cabin Bilişim Teknolojileri A.Ş <br />
      Ortabayır Mah. Şair Çelebi Sk. No:1/3 Kağıthane, İstanbul – Türkiye
    </div>
    <div class="col text-right mr-3">
      Bilgi ve Destek: <br />
      <b>info@cabin.com.tr</b>
    </div>
  </div>
</section>

<iframe
  class="mx-auto"
  id="cabinIframe"
  [src]="urlSafe"
  width="100%"
  height="100%"
  *ngIf="showModal"
  style="border: none; position: fixed; top: 0; left: 0; z-index: 999999"
></iframe>
