<div class="shadow p-3">
  <div class="row">
    <div class="col">
        <div style="width: 50%; margin-left: 25%;">
        <b>    Cabin Bilişim Teknolojileri A.Ş. </b>  </div> 
             <div style="width: 50%; margin-left: 25%;">
                Ortabayır Mah. Şair Çelebi Sk. No:1/3
             </div>
             <div style="width: 50%; margin-left: 25%;">
                 Kağıthane, İstanbul – Türkiye
             </div>
      
      
        
     
    </div>
    <div class="col">
        <img class="w-25" style="margin-left: 37.5%;" src="../../assets/CabinFit.png" alt="">
    </div>

    <div class="col text-center">
        <div style="width: 40%; margin-left: 30%;">
           <b> İletişim için: </b>
        </div>
        <div style="width: 40%; margin-left: 30%;">
            enes@cabin.com.tr 
        </div>
    </div>
  </div>
</div>
