<div class=" bg-white shadow p-3">
    <div class="row">
      <div class="col">
          <div class="mx-auto">
              <!-- <img class="brandLogo" style="width: 40%; margin-left: 0;" src="../../assets/CabinFit.png" alt="" /> -->
            </div>
      </div>
      <div class="col">
        <div >
          <img class="cabinLogo"  src="../../assets/CabinFit.png" alt="" />
        </div>
      </div>
      <div class="col my-auto">
          <span class="float-right my-auto" style="font-size: 14px; cursor: pointer; color: #707070; opacity: 0.7; font-weight: bolder;">
              Bilgilendirme
          </span>
          </div>
    </div>
  </div>
  